<template>
  <div id="footer">
    <div class="inner-fixed">
      <ul class="f0">
        <li class="fl logo"><img src="@/assets/images/logo-only-white.svg" alt="아크릴"></li>
        <li class="fr sns">
          <a @click="openTo('https://www.instagram.com/i_acryl/')"><img src="@/assets/images/icon-instagram@3x.png" alt="instagram"></a>
          <a @click="openTo('https://facebook.com/iacryl/')"><img src="@/assets/images/icon-facebook@3x.png" alt="facebook"></a>
          <a @click="openTo('https://youtube.com/channel/UCcRW2Kk624bX0d79KsCcBBg/')"><img src="@/assets/images/icon-youtube@3x.png" alt="youtube"></a>
        </li>
      </ul>

      <div class="address">
        7F/11F, Chungdam Venture Plaza. 704, Seolleung-ro, <br class="m-block">Gangnam-gu, Seoul, Korea (06069)
      </div>
      <div class="info">
        <a href="tel:+82 2.577.4958" class="tel">TEL<i class="bar"></i>+82.2.557.4958 </a>
				<span class="fax">FAX<i class="bar"></i>+82.2.558.4958</span>
				<a href="mailto:info@acryl.ai" class="mail">MAIL<i class="bar"></i>info@acryl.ai</a>
      </div>
      <ul class="f0">
        <li class="fl">
          <div class="copy">
            © 2023 Acryl inc. All rights reserved.
          </div>
        </li>
        <li class="fr">
          <select id="select-locale">
            <option value="ko" :selected="$_gGet_Locale() === 'ko'" :data-image="ko_image">한국어</option>
            <option value="en" :selected="$_gGet_Locale() === 'en'" :data-image="en_image">English</option>
          </select>
        </li>
        <li class="fr">
          <select @change="onChange">
            <option selected value="">FAMILY SITE</option>
            <option value="https://jonathan.acryl.ai/">Jonathan®</option>
            <option value="http://hugbot.acryl.ai">HUGbot™</option>
          </select>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { openTo } from '@/assets/js/util.js'
import '@/assets/js/jquery.dd.js'
import '@/assets/css/dd.css'
import $ from 'jquery'

export default {
  data: () => ({
    ko_image: require('@/assets/images/img-country-kor@3x.png'),
    en_image: require('@/assets/images/img-country-eng@3x.png')
  }),
  methods: {
    openTo (path) { openTo(path, true) },
    onChange (event) { 
      if(event.target.value) {
        this.openTo(event.target.value)
        event.target.value = ''
      }
    },
  },
  mounted: function () {
    var _this = this
    $(document).ready(function(e) {
      $("#select-locale").msDropDown();
      $("#select-locale").change(function(e) {
        const {value} = e.target;
        _this.$i18n.locale = value;
        localStorage.setItem('locale', value)
      })
    });
  }
}
</script>