<template>
  <main v-bind:id="this.$route.name">
    <div id="skip-navigation">
      <ol>
        <li><a href="#header-wrap">{{ $t('common.shortcut.top') }}</a></li>
        <li><a href="#contents">{{ $t('common.shortcut.body')}}</a></li>
      </ol>
      <hr />
    </div>
    <div id="wrap">
      <AppHeader/>
      <router-view></router-view>
      <!-- scrollup -->
      <a href="#" class="scrollup" :style="{'display': 'none'}">Scroll</a>
      <!-- //scrollup -->
      <AppFooter/>
    </div>
  </main>
</template>

<script>
// import $ from 'jquery'
import AppHeader from '@/components/AppHeader/AppHeader.vue'
import AppFooter from '@/components/AppFooter/AppFooter.vue'
import '@/assets/js/common.js'
import 'jquery'

export default {
  name: 'AcrylPage',
  data: () => ({
  }),
  components: {
    AppHeader,
    AppFooter
  },
}
</script>
