<template>
    <div class="fat-nav__wrapper">
      <ul>
        <li><a :class="[currentPage.includes('acryl') ? activeClass : '']" @click="handleLink('acryl')">{{ $t("gnb.acryl") }}</a></li>
        <li><a :class="[currentPage.includes('products') ? activeClass : '']" @click="handleLink('products')">{{ $t("gnb.service") }}</a></li>
        <li><a :class="[currentPage.includes('news') ? activeClass : '']" @click="handleLink('news')">{{ $t("gnb.news") }}</a></li>
        <li><a :class="[currentPage.includes('partners') ? activeClass : '']" @click="handleLink('partners')">{{ $t("gnb.partner") }}</a></li>
        <li><a :class="[currentPage.includes('recruitment') ? activeClass : '']" @click="handleLink('recruitment')">{{ $t("gnb.recruit") }}</a></li>
        <li><a :class="[currentPage.includes('wellcare') ? activeClass : '']" @click="handleLink('wellcare')">{{ $t("gnb.wellcare") }}</a></li>
        <li>
          <a :class="[currentPage.includes('contactUs') ? activeClass : '', [`${$_gGet_Locale()} button-business`]]" @click="handleLink('business')">
          {{ $t("gnb.business") }}
          </a>
        </li>
      </ul>
    </div>
</template>

<script>
import { handleLink, fatNav } from '@/assets/js/util.js'

export default {
  name: 'Gnb',
  data: () => ({
    activeClass: 'active',
  }),
  methods: {
    handleLink (path) { handleLink(this.$router, path) },
  },
  computed: {
    currentPage () {
      return this.$route.path
    }
  },
  mounted: function () {
    fatNav();
  }
}
</script>
