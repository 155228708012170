import $ from 'jquery'
/* eslint-disable no-undef */
// top scrollup
$(document).ready(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn()
    } else {
      $('.scrollup').fadeOut()
    }
  })

  $('.scrollup').click(function () {
    $('html, body').animate({ scrollTop: 0 }, 600)
    return false
  })

  //gnb scroll bg
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$('.nav-bg').fadeIn();
		} else {
			$('.nav-bg').fadeOut();
		}
	}); 
	$('.nav-bg').click(function(){
		$("html, body").animate({ scrollTop: 0 }, 600);
		return false;
	});
})
