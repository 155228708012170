<template>
  <div>
    <div id="header-wrap">
      <nav class="gnb inner-fixed">
        <h1><a @click="goToHome()">{{ $t('gnb.acryl') }}</a></h1>
        <div class="fat-nav">
          <h1><a @click="goToHome()">{{ $t('gnb.acryl') }}</a></h1>
          <Gnb></Gnb>
        </div>
      </nav>
    </div>
    <div class="nav-bg"></div>
  </div>
</template>


<script>
import Gnb from '@/components/Gnb/Gnb.vue'

export default {
  name: 'AppHeader',
  components: {
    Gnb
  },
  methods: {
    goToHome () {
      this.$router.push({name: 'main'}).catch(() => {})
    }
  }
}
</script>
